import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Tabs from "../components/user-profile/Tabs";
import TabContentUserProfile from "../components/user-profile/TabContentUserProfile";
import UserAddressHistory from "../components/user-profile/UserAddressHistory";
import UserDelete from "../components/user-profile/UserDelete";
import UserChangePassword from "../components/user-profile/UserChangePassword";
import ConfirmationLoadingBucketCreating from "../components/ConfirmationLoadingBucketCreating";

const UserProfile = ({ staticBasePath, djangoContext }) => {
  const [changedUserName, setChangedUserName] = useState(false);

  const [activeTabUserProfile, setActiveTabUserProfile] = useState(
    window.location.hash || "#user-info",
  );

  const [userAddressHistoryData, setUserAddressHistoryData] = useState("");

  const [userDelete, setUserDelete] = useState(false);

  const [changePassword, setChangePassword] = useState(false);

  // Update active tab based on URL hash change
  useEffect(() => {
    const handleHashChangeUserProfile = () => {
      setActiveTabUserProfile(window.location.hash || "#user-info");
    };

    window.addEventListener("hashchange", handleHashChangeUserProfile);

    return () => {
      window.removeEventListener("hashchange", handleHashChangeUserProfile);
    };
  }, []);

  // Function to switch tab and update URL hash
  const switchTabUserProfile = (tabId) => {
    window.location.hash = tabId;
    setActiveTabUserProfile(tabId);
  };

  // Update user Quota after creating Bucket
  const [updateStampQuota, setUpdateStampQuota] = useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleUpdateStampQuota = useCallback((date) => {
    setUpdateStampQuota(date);
  }, []);

  // Show confirmation create bucket
  const [showConfirmationBucketCreate, setShowConfirmationBucketCreate] =
    useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleShowConfirmationBucketCreate = useCallback((isDisabled) => {
    setShowConfirmationBucketCreate(isDisabled);
  }, []);

  //State for storing the name
  const [nameBucket, setNameBucket] = useState("");

  // Memoize the callback function to avoid unnecessary re-renders
  const handleNameBucket = useCallback((bucket) => {
    setNameBucket(bucket);
  }, []);

  //Error adding name
  const [nameBucketError, setNameBucketError] = useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleNameBucketError = useCallback((bucketError) => {
    setNameBucketError(bucketError);
  }, []);

  //Successfully added name
  const [nameBucketSuccess, setNameBucketSuccess] = useState("");

  // Memoize the callback function to avoid unnecessary re-renders
  const handleNameBucketSuccess = useCallback((bucketSuccess) => {
    setNameBucketSuccess(bucketSuccess);
  }, []);

  return (
    <>
      {showConfirmationBucketCreate && (
        <div className="rb-screen">
          <ConfirmationLoadingBucketCreating
            titleLoading="One moment! Creating new Collection in progress..."
            textLoading="Can take 20 seconds, but usually faster!"
            titleConfirmation="You are about to create a new collection via vBase."
            textConfirmation="This will permanently associate the new collection with your address. The cost of this operation is 1 stamp credit."
            nameBucket={nameBucket}
            onNameBucket={handleNameBucket}
            onNameBucketSuccess={handleNameBucketSuccess}
            onNameBucketError={handleNameBucketError}
            onShowConfirmationBucketCreate={handleShowConfirmationBucketCreate}
            onUpdateStampQuota={handleUpdateStampQuota}
          />
        </div>
      )}

      <Header
        staticBasePath={staticBasePath}
        djangoContext={djangoContext}
        changedUserName={changedUserName}
        updateStampQuota={updateStampQuota}
      />

      <div className="rb-main-content user-profile">
        <div className="rb-container">
          <div className="user-profile-header">
            <h1 className="rb-header rb-header-stamp rb-text-center">
              User Profile
            </h1>
          </div>
        </div>
        <Tabs
          activeTabUserProfile={activeTabUserProfile}
          switchTabUserProfile={switchTabUserProfile}
        />
        <div className="user-profile-tabs-content">
          <div className="rb-container">
            <TabContentUserProfile
              activeTabUserProfile={activeTabUserProfile}
              setUserAddressHistoryData={setUserAddressHistoryData}
              setUserDelete={setUserDelete}
              setChangePassword={setChangePassword}
              setChangedUserName={setChangedUserName}
              changedUserName={changedUserName}
              staticBasePath={staticBasePath}
              onUpdateStampQuota={handleUpdateStampQuota}
              onShowConfirmationBucketCreate={
                handleShowConfirmationBucketCreate
              }
              showConfirmationBucketCreate={showConfirmationBucketCreate}
              onNameBucket={handleNameBucket}
              nameBucket={nameBucket}
              nameBucketSuccess={nameBucketSuccess}
              onNameBucketError={handleNameBucketError}
              nameBucketError={nameBucketError}
            />
          </div>
        </div>
        {showConfirmationBucketCreate && <div className="rb-loading-bg"></div>}
      </div>

      <Footer staticBasePath={staticBasePath} />

      {userAddressHistoryData ? (
        <UserAddressHistory
          staticBasePath={staticBasePath}
          userAddressHistoryData={userAddressHistoryData}
          setUserAddressHistoryData={setUserAddressHistoryData}
        />
      ) : (
        ""
      )}

      {userDelete ? (
        <UserDelete
          staticBasePath={staticBasePath}
          setUserDelete={setUserDelete}
        />
      ) : (
        false
      )}

      {changePassword ? (
        <UserChangePassword
          staticBasePath={staticBasePath}
          setChangePassword={setChangePassword}
        />
      ) : (
        false
      )}
    </>
  );
};
UserProfile.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  djangoContext: PropTypes.object.isRequired,
  onUpdateStampQuota: PropTypes.bool,
  showConfirmationBucketCreate: PropTypes.func,
  handleNameBucket: PropTypes.func,
};

export default UserProfile;
