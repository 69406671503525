import {
  sha3_256_hash_bytes,
  keccak256_hash_bytes,
} from "../utils/cryptoUtils";
/**
 * Process file and generate hashes.
 * @param {*} file  The file to process.
 * @param {*} setObjectHash  The function to set the object hash.
 * @param {*} handleUpdateHashWorker  The function to handle the update hash worker.
 */
export const processFile = (
  file,
  setObjectHash,
  handleUpdateHashWorker,
  setAllHashes = {},
) => {
  const reader = new FileReader();

  reader.onload = function () {
    try {
      const arrayBuffer = reader.result;
      const byteArray = new Uint8Array(arrayBuffer);
      const hash = sha3_256_hash_bytes(byteArray);
      const hash2 = keccak256_hash_bytes(byteArray);
      setObjectHash(hash);
      const supportedHashAlgoMap = {
        [hash]: "SHA3-256",
        [hash2]: "Keccak-256",
      };

      handleUpdateHashWorker(hash, supportedHashAlgoMap);

      // All Hashes supported
      setAllHashes(supportedHashAlgoMap);
    } catch (error) {
      console.error("Error processing file:", error);
    }
  };
  reader.onerror = function (error) {
    console.error("File reading error:", error);
  };
  reader.readAsArrayBuffer(file);
};
