import PropTypes from "prop-types";
import React from "react";
import UserInfo from "./UserInfo";
import Billing from "./Billing";
import AccountSettings from "./AccountSettings";
import UsageStatistics from "./UsageStatistics";
import BucketNames from "./BucketNames";

const TabContentUserProfile = ({
  activeTabUserProfile,
  setUserAddressHistoryData,
  setUserDelete,
  setChangePassword,
  setChangedUserName,
  changedUserName,
  staticBasePath,
  onUpdateStampQuota,
  onShowConfirmationBucketCreate,
  showConfirmationBucketCreate,
  onNameBucket,
  nameBucket,
  nameBucketSuccess,
  onNameBucketError,
  nameBucketError,
}) => {
  switch (activeTabUserProfile) {
    case "#user-info":
      return (
        <UserInfo
          setUserAddressHistoryData={setUserAddressHistoryData}
          setUserDelete={setUserDelete}
          setChangePassword={setChangePassword}
          setChangedUserName={setChangedUserName}
          changedUserName={changedUserName}
        />
      );
    case "#billing":
      return <Billing />;
    case "#account-settings":
      return <AccountSettings />;
    case "#usage-statistics":
      return <UsageStatistics />;
    case "#collections":
      return (
        <BucketNames
          staticBasePath={staticBasePath}
          onUpdateStampQuota={onUpdateStampQuota}
          onShowConfirmationBucketCreate={onShowConfirmationBucketCreate}
          showConfirmationBucketCreate={showConfirmationBucketCreate}
          onNameBucket={onNameBucket}
          nameBucket={nameBucket}
          nameBucketSuccess={nameBucketSuccess}
          onNameBucketError={onNameBucketError}
          nameBucketError={nameBucketError}
        />
      );
    default:
      return (
        <UserInfo
          setUserAddressHistoryData={setUserAddressHistoryData}
          setUserDelete={setUserDelete}
          setChangePassword={setChangePassword}
        />
      );
  }
};
TabContentUserProfile.propTypes = {
  activeTabUserProfile: PropTypes.string.isRequired,
  setUserAddressHistoryData: PropTypes.func.isRequired,
  setUserDelete: PropTypes.func.isRequired,
  setChangePassword: PropTypes.func.isRequired,
  setChangedUserName: PropTypes.func.isRequired,
  changedUserName: PropTypes.string,
  staticBasePath: PropTypes.string.isRequired,
  onUpdateStampQuota: PropTypes.func.isRequired,
  onNameBucket: PropTypes.func.isRequired,
  nameBucket: PropTypes.string.isRequired,
  nameBucketSuccess: PropTypes.string.isRequired,
  onNameBucketError: PropTypes.func.isRequired,
  nameBucketError: PropTypes.string.isRequired,
  onShowConfirmationBucketCreate: PropTypes.func,
  showConfirmationBucketCreate: PropTypes.bool.isRequired,
};
export default TabContentUserProfile;
