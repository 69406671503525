import React, { useCallback } from "react";
import { jsPDF } from "jspdf";

const DownloadPDF = ({ data }) => {
  const { user_id, objectCid, blockExplorerUrl, transactionHash } = data;

  const generatePDF = useCallback(() => {
    const doc = new jsPDF();
    const centerX = doc.internal.pageSize.getWidth() / 2;

    // Set header
    doc.setFont("helvetica", "bold").setFontSize(16);
    doc.text("Technical Details", centerX, 10, { align: "center" });

    // Set body
    doc.setFontSize(10).setFont("helvetica", "normal");

    // Account ID
    const accountLabel = "Account ID: ";
    const accountIDX = doc.getTextWidth(accountLabel) + 10;
    doc.text(accountLabel, 10, 20).setFont("helvetica", "bold");
    doc.text(`${user_id}`, accountIDX, 20);

    // Content ID
    doc.setFont("helvetica", "normal");
    const contentLabel = "Content ID: ";
    const contentIDX = doc.getTextWidth(contentLabel) + 10;
    doc.text(contentLabel, 10, 27).setFont("helvetica", "bold");
    doc.text(`${objectCid}`, contentIDX, 27);

    // Blockchain Receipt
    doc.setFont("helvetica", "normal");
    const receiptLabel = "Blockchain Receipt: ";
    const linkX = doc.getTextWidth(receiptLabel) + 10;
    doc.text(`${receiptLabel}Link`, 10, 34).setFont("helvetica", "bold");
    doc.link(linkX, 30, doc.getTextWidth("Link"), 6, {
      url: `${blockExplorerUrl}${transactionHash}`,
    });

    // Save the PDF
    doc.save("report.pdf");
  }, [user_id, objectCid, blockExplorerUrl, transactionHash]);

  return (
    <button className="download-pdf" onClick={generatePDF}>
      Download PDF
    </button>
  );
};

export default DownloadPDF;
