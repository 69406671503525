import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

import LendingPricingSvg from "../svg/LendingPricingSvg";
import CurrentPlanSvg from "../svg/CurrentPlanSvg";
import LoadingPayment from "../components/LoadingPayment";
import { fetchConfigData, URLs } from "../utils/apis";
import BackToFreePlanWindow from "../components/BackToFreePlanWindow";

const paddleConfig = {
  sandbox_new: {
    products: {
      Base: {
        productName: "Base",
        productId: "pro_01j99wrpvynzbxhv94cgy29zwb",
        priceIdMonth: "pri_01j99wxqd1ra3tvpa2c40zev3p",
        priceIdYear: "pri_01j99x26bvh6cnaacgzjxfftr5",
      },
      Pro: {
        productName: "Pro",
        productId: "pro_01j99wrpvynzbxhv94cgy29zwb",
        priceIdMonth: "pri_01j99x65mdecb9xwxn0cm7y8nw",
        priceIdYear: "pri_01j99xcz3mc6ycspb9kxt498vc",
      },
    },
  },
  sandbox: {
    products: {
      Base: {
        productName: "Base",
        productId: "pro_01j6jb3adekkcpe8z5qvpjqjb0",
        priceIdMonth: "pri_01j6jb5b9d2k4jf00pgvanrfpa",
        priceIdYear: "pri_01j6jdsevvgbnmb033ypks3efs",
      },
      Pro: {
        productName: "Pro",
        productId: "pro_01j6jb68bm4cd3xamnn99tb5ce",
        priceIdMonth: "pri_01j6jb75v2cyvf89g6c1mvrvn4",
        priceIdYear: "pri_01j6jdy5xgeawrn6mgsstn9wbe",
      },
    },
  },
  production: {
    products: {
      Base: {
        productName: "Base",
        productId: "pro_01j9a9f7g6eq6ga7928h4h5qrr",
        priceIdMonth: "pri_01j9a9hfew5tahn9qkd663kc1r",
        priceIdYear: "pri_01j9a9m5mqjqj88p8rax289044",
      },
      Pro: {
        productName: "Pro",
        productId: "pro_01j9a9f7g6eq6ga7928h4h5qrr",
        priceIdMonth: "pri_01j9a9p5dgkvn3f31hn7518gks",
        priceIdYear: "pri_01j9a9s023148yya7xwrfzg79e",
      },
    },
  },
};

const PricingForm = ({ initialHash = "", staticBasePath, djangoContext }) => {
  const [billingCycle, setBillingCycle] = useState("Monthly");
  const [starterPrice, setStarterPrice] = useState("");
  const [starterPriceYear, setStarterPriceYear] = useState("");
  const [proPrice, setProPrice] = useState("");
  const [proPriceYear, setProPriceYear] = useState("");

  const [loading, setLoading] = useState(false);
  const { userIsAuthenticated, userEmail } = djangoContext;
  const [activePlan, setActivePlan] = useState("Free");
  const [activePeriod, setActivePeriod] = useState("");

  const customerInfo = {
    email: userEmail,
  };

  let productsRef = useRef(null);

  async function waitForCompletedTransactionStatus(transaction_id) {
    // Unfortunately, payment confirmation from PayPal might take around 10 seconds.

    try {
      const maxAttempts = 15; // Maximum number of attempts (30 seconds)
      let attempts = 0; // Initialize the counter
      while (attempts < maxAttempts) {
        try {
          const response = await fetch(
            URLs.PADDLE_TRANSACTION_STATUS_URL + transaction_id,
          );

          if (!response.ok) {
            throw new Error(
              `Failed to fetch transaction status: ${response.statusText}`,
            );
          }
          const data = await response.json();
          if (data.paddle_status === "completed") {
            await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds
            console.log("Transaction completed!");
            return true;
          }
          console.log("Transaction still in progress...");
        } catch (error) {
          console.log("Error checking transaction status:", error);
        }
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds
        attempts++; // Increment the counter after each attempt
      }
      if (attempts >= maxAttempts) {
        console.log("Error checking transaction status");
        location.href = URLs.SUBSCRIPTION_FAILED_URL + transaction_id;
        return false;
      }
    } catch (error) {
      console.log("Error checking transaction status:", error);
    }
  }

  const paddleData = async () => {
    try {
      const response = await fetch(URLs.PADDLE_DETAILS_URL);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      const subscription_price_paddle_id =
        data[0]?.subscription_info?.subscription_price_paddle_id || false;
      const products = productsRef.current;
      if (products) {
        if (products.Base.priceIdMonth === subscription_price_paddle_id) {
          setActivePlan("Base");
          setActivePeriod("Monthly");
        } else if (products.Base.priceIdYear === subscription_price_paddle_id) {
          setActivePlan("Base");
          setActivePeriod("Yearly");
        } else if (products.Pro.priceIdMonth === subscription_price_paddle_id) {
          setActivePlan("Pro");
          setActivePeriod("Monthly");
        } else if (products.Pro.priceIdYear === subscription_price_paddle_id) {
          setActivePlan("Pro");
          setActivePeriod("Yearly");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPrices = async (cycle) => {
    try {
      const products = productsRef.current;
      const itemsList =
        cycle === "Monthly"
          ? Object.values(products).map((product) => ({
              quantity: 1,
              priceId: product.priceIdMonth,
            }))
          : Object.values(products).map((product) => ({
              quantity: 1,
              priceId: product.priceIdYear,
            }));

      console.log("itemsList", itemsList);
      const result = await Paddle.PricePreview({ items: itemsList });

      const items = result.data.details.lineItems;
      console.log("items", items);
      items.forEach((item) => {
        if (item.price.customData.product_type === products.Base.productName) {
          setStarterPrice(item.price.customData.price_month);
          products.Base.priceLabel = item.price.customData.price_month;
          setStarterPriceYear(item.price.customData.price_year);
          products.Base.priceLabelYear = item.price.customData.price_year;
        } else if (
          item.price.customData.product_type === products.Pro.productName
        ) {
          setProPrice(item.price.customData.price_month);
          products.Pro.priceLabel = item.price.customData.price_month;
          setProPriceYear(item.price.customData.price_year);
          products.Pro.priceLabelYear = item.price.customData.price_year;
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckout = (productName) => {
    const product = productsRef.current[productName];
    const items = [
      {
        quantity: 1,
        priceId:
          billingCycle === "Monthly"
            ? product.priceIdMonth
            : product.priceIdYear,
      },
    ];

    Paddle.Checkout.open({
      items,
      customer: customerInfo,
      customData: {
        email: userEmail,
      },
    });
  };

  const initPaddle = (mode, token) => {
    Paddle.Environment.set(mode);
    Paddle.Initialize({
      token: token,
      checkout: {
        settings: {
          allowLogout: false,
        },
      },
      eventCallback: async function (data) {
        if (data.name === "checkout.completed") {
          setLoading(true);
          Paddle.Checkout.close();
          handleTransaction(data.data.transaction_id);
        }
      },
    });
  };
  async function handleTransaction(transaction_id) {
    const transactionStatus = await waitForCompletedTransactionStatus(
      transaction_id,
    );

    if (transactionStatus) {
      location.href = URLs.SUBSCRIPTION_SUCCESS_URL + transaction_id;
    } else {
      console.log("Failed to save the transaction data.");

      location.href = URLs.SUBSCRIPTION_FAILED_URL + transaction_id;
    }
  }
  const fetchData = async () => {
    try {
      const data = await fetchConfigData();
      console.log(data);

      const { PADDLE_ENVIRONMENT, PADDLE_CLIENT_SIDE_TOKEN } = data;
      initPaddle(PADDLE_ENVIRONMENT, PADDLE_CLIENT_SIDE_TOKEN);

      productsRef.current = paddleConfig[PADDLE_ENVIRONMENT].products;
      await fetchPrices("Monthly");
    } catch (error) {
      console.error("Error fetching config data:", error);
    }
  };

  useEffect(() => {
    if (productsRef.current) {
      fetchPrices(billingCycle);
    }
  }, [billingCycle]);

  // Fetch user data from API
  useEffect(() => {
    fetchData();
    paddleData();
  }, []); // runs once on mount

  const [backToFreeWindow, setBackToFreeWindow] = useState(false);
  const handleClickBackToFreeWindow = () => {
    setBackToFreeWindow(true);
    document.body.style.overflow = "hidden";
  };

  if (!productsRef.current) {
    return <></>;
  }

  // Button plan texts
  const renderPlanSwitch = (index, activePeriod, activePlan) => {
    if (index === 0) {
      if (activePlan === "Base") {
        return activePeriod === "Monthly" ? (
          <>Switch to Annual Plan</>
        ) : (
          <>Switch to Monthly Plan</>
        );
      } else if (activePlan === "Free") {
        return <>Upgrade to Base</>;
      } else if (activePlan === "Pro") {
        return <>Switch to Base</>;
      }
    }

    if (index === 1) {
      if (activePlan === "Pro") {
        return activePeriod === "Monthly" ? (
          <>Switch to Annual Plan</>
        ) : (
          <>Switch to Monthly Plan</>
        );
      } else {
        return <>Upgrade to Pro</>;
      }
    }

    return null;
  };
  return (
    <>
      <Header staticBasePath={staticBasePath} djangoContext={djangoContext} />
      <div className="rb-main-content pricing-page">
        {loading && (
          <div className="rb-screen">
            <LoadingPayment />
          </div>
        )}

        {backToFreeWindow && (
          <div className="rb-screen">
            <BackToFreePlanWindow setBackToFreeWindow={setBackToFreeWindow} />
          </div>
        )}

        <div className="rb-container">
          <div className="pricing-page-header pricing-header">
            <div className="checkout-container"></div>
            <h1 className="rb-header rb-header-stamp rb-text-center">
              Choose your plan
            </h1>
          </div>
          <div className="pricing-page-periods pricing-periods rb-btns-select rb-display-flex rb-justify-content-center">
            <div
              onClick={() => setBillingCycle("Monthly")}
              className={`pricing-page-periods-item pricing-page-periods-month rb-radio-button rb-btn rb-display-flex rb-justify-content-center ${
                billingCycle === "Monthly" ? "pricing-page-periods-active" : ""
              }`}
            >
              <input
                type="radio"
                name="plan"
                value="month"
                id="month"
                defaultChecked
              />
              <label htmlFor="month">Monthly</label>
            </div>
            <div
              onClick={() => setBillingCycle("Yearly")}
              className={`pricing-page-periods-item pricing-periods-item pricing-page-periods-year rb-radio-button rb-btn rb-display-flex rb-justify-content-center ${
                billingCycle === "Yearly" ? "pricing-page-periods-active" : ""
              }`}
            >
              <input type="radio" name="plan" value="year" id="year" />
              <label htmlFor="year">
                Yearly <sup>Save 20%</sup>
              </label>
            </div>
          </div>

          <div className="pricing-page-diy-wrap rb-display-flex rb-justify-content-center">
            <div className="pricing-page-diy rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap rb-justify-content-center">
              <img
                src={`${staticBasePath}public/images/svg/diy.svg`}
                alt="DIY"
              />
              <span>DIY:</span>
              <p>unlimited free stamps and validations forever.</p>
              <a target="_blank" href="https://docs.vbase.com/">
                Always an option.
              </a>
            </div>
          </div>

          <div className="pricing-page-plans pricing-page-block pricing-block rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap">
            <div
              className={`pricing-block-item pricing-block-item-first pricing-page-plans-item-first rb-display-flex
                    ${
                      activePlan === "Free"
                        ? "pricing-page-block-item-active"
                        : ""
                    }`}
            >
              <div className="pricing-block-item-head pricing-page-block-item-head pricing-block-item-head rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap">
                <div className="pricing-page-block-item-head-title pricing-block-item-head-title">
                  Free
                </div>
                <div className="pricing-page-block-item-head-subtitle pricing-block-item-head-subtitle"></div>
                <div className="pricing-page-block-item-head-wrap rb-display-flex rb-justify-content-center rb-align-items-flex-start">
                  {activePlan === "Free" && (
                    <div className="pricing-page-block-item-head-current pricing-block-item-head-current">
                      Current Plan
                    </div>
                  )}
                </div>
              </div>
              <div className="pricing-page-block-item-body pricing-block-item-body">
                <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                  <LendingPricingSvg />
                  <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                    100 stamps / month (+$0.20/extra)
                  </div>
                </div>

                <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                  <LendingPricingSvg />
                  <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                    Unlimited validations*
                  </div>
                </div>
                <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                  <LendingPricingSvg />
                  <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                    E-mail and chat support
                  </div>
                </div>

                <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                  <LendingPricingSvg />
                  <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                    vBase APIs & Application Hub
                  </div>
                </div>

                <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                  <LendingPricingSvg />
                  <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                    vBase DataPIT listing
                  </div>
                </div>
              </div>

              <div className="pricing-page-block-item-footer pricing-block-item-footer rb-display-flex rb-justify-content-center">
                {activePlan === "Free" ? (
                  <CurrentPlanSvg />
                ) : (
                  <div className="pricing-page-block-buttons pricing-block-buttons pricing-page-plans-item-buttons rb-display-flex rb-justify-content-center rb-align-items-center">
                    <a
                      href="#"
                      onClick={() => handleClickBackToFreeWindow()}
                      className="button button-white rb-display-flex rb-align-items-center"
                    >
                      Cancel Subscription
                    </a>
                  </div>
                )}
              </div>
            </div>

            {Object.values(productsRef.current).map((product, index) => (
              <div
                key={product.productName}
                className={`pricing-page-block-item pricing-block-item pricing-page-plans-item rb-display-flex ${product.productName.toLowerCase()}-plan ${
                  index === 0
                    ? "pricing-block-item-second pricing-page-plans-item-second"
                    : "pricing-block-item-third pricing-page-plans-item-third"
                }
                    ${
                      (activePlan === "Base" && index === 0) ||
                      (activePlan === "Pro" && index === 1)
                        ? "pricing-page-block-item-active"
                        : ""
                    }`}
              >
                <div className="pricing-page-block-item-head pricing-block-item-head rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap">
                  <div className="pricing-page-block-item-head-title pricing-block-item-head-title">
                    {product.productName}
                  </div>
                  <div className="pricing-page-block-item-head-subtitle pricing-block-item-head-subtitle">
                    {product.priceLabel}
                    <span>/mo</span>
                  </div>

                  <div className="pricing-page-block-item-head-wrap rb-display-flex rb-flex-wrap-wrap rb-justify-content-center">
                    {((activePlan === "Base" && index === 0) ||
                      (activePlan === "Pro" && index === 1)) && (
                      <div className="pricing-page-block-item-head-current pricing-block-item-head-current">
                        Current Plan/{activePeriod}
                      </div>
                    )}

                    <div className="pricing-page-block-item-head-subtitle-year pricing-block-item-head-subtitle-year rb-text-center">
                      &nbsp;
                      {billingCycle === "Yearly" && (
                        <>{product.priceLabelYear}</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="pricing-page-block-item-body pricing-block-item-body">
                  {index === 0 ? (
                    <>
                      <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                        <LendingPricingSvg />
                        <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                          400 stamps / month (+$0.12/extra)
                        </div>
                      </div>

                      <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                        <LendingPricingSvg />
                        <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                          Managed service for S3, Snowflake, Dropbox and more
                        </div>
                      </div>

                      <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                        <LendingPricingSvg />
                        <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                          White glove onboarding
                        </div>
                      </div>

                      <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                        <LendingPricingSvg />
                        <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                          All features of free plan
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                        <LendingPricingSvg />
                        <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                          2000 stamps/month (+$0.10/extra)
                        </div>
                      </div>

                      <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                        <LendingPricingSvg />
                        <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                          Enterprise grade support
                        </div>
                      </div>
                      <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                        <LendingPricingSvg />
                        <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                          vBase Live Sync for real-time assurance
                        </div>
                      </div>

                      <div className="pricing-page-block-item-body-item pricing-block-item-body-item rb-display-flex">
                        <LendingPricingSvg />
                        <div className="pricing-page-block-item-body-item-text pricing-block-item-body-item-text">
                          All features of $50/month plan
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="pricing-page-block-item-footer pricing-block-item-footer rb-display-flex rb-justify-content-center">
                  {(activePlan === "Base" &&
                    index === 0 &&
                    billingCycle === activePeriod) ||
                  (activePlan === "Pro" &&
                    index === 1 &&
                    billingCycle === activePeriod) ? (
                    <CurrentPlanSvg />
                  ) : (
                    <div className="pricing-page-block-buttons pricing-block-buttons pricing-page-plans-item-buttons rb-display-flex rb-justify-content-center rb-align-items-center">
                      <a
                        className={`button rb-display-flex rb-align-items-center ${
                          index === 0 ? "button-white" : "button-dark"
                        }`}
                        href="#"
                        onClick={() => handleCheckout(product.productName)}
                      >
                        {renderPlanSwitch(index, activePeriod, activePlan)}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ))}

            <div className="pricing-page-plans-custom rb-display-flex">
              <div className="pricing-page-plans-custom-item pricing-page-plans-custom-item-first">
                <div className="pricing-page-plans-custom-item-title">
                  Need Custom Pricing?
                </div>
                <div className="pricing-page-plans-custom-item-buttons rb-display-flex">
                  <a
                    target="_blank"
                    className="button rb-btn button-dark rb-display-flex rb-align-items-center"
                    href={URLs.CONTACTS_URL}
                  >
                    Contact Us
                  </a>
                </div>
              </div>
              <div className="pricing-page-plans-custom-item pricing-page-plans-custom-item-second">
                <div className="pricing-page-plans-custom-item-mobile">
                  <span>Please Contact</span>
                  <a href="mailto:support@vbase.com">support@vbase.com</a>
                </div>
                <div className="pricing-page-plans-custom-item-line rb-display-flex">
                  <LendingPricingSvg />
                  <div className="pricing-page-plans-custom-item-line-text">
                    <b>Customized Solutions:</b> Tailored features,
                    integrations, and support
                  </div>
                </div>
                <div className="pricing-page-plans-custom-item-line rb-display-flex">
                  <LendingPricingSvg />
                  <div className="pricing-page-plans-custom-item-line-text">
                    <b>Priority Support:</b> 24/7 dedicated support with a
                    personal account manager
                  </div>
                </div>
                <div className="pricing-page-plans-custom-item-line rb-display-flex">
                  <LendingPricingSvg />
                  <div className="pricing-page-plans-custom-item-line-text">
                    <b>Dedicated Infrastructure:</b> Enhanced performance and
                    scaled usage allowances
                  </div>
                </div>
              </div>
              <div className="pricing-page-plans-custom-item">
                <img
                  className="pricing-page-plans-custom-item-img"
                  src={`${staticBasePath}public/images/png/custom-plan.png`}
                  alt="Custom Plan"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer staticBasePath={staticBasePath} />
      {loading && <div className="rb-loading-bg"></div>}
      {backToFreeWindow && <div className="rb-loading-bg"></div>}
    </>
  );
};

export default PricingForm;
