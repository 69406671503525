import React, { useEffect } from 'react';
import GuaranteedData from "./GuaranteedData";
import VerifiableData from "./VerifiableData";
import CustomerReviews from "./CustomerReviews";
import Pricing from "./Pricing";
import SupplementaryInformation from "./SupplementaryInformation";
import TransformDataSecurity from "./TransformDataSecurity";

const Landing = ({staticBasePath, landingSectionRef, isAuthenticated}) => {
  useEffect(() => {
  }, [landingSectionRef]);

  return (
    <>

      <div className="landing" ref={landingSectionRef}>
        <GuaranteedData staticBasePath={staticBasePath} />
        <VerifiableData staticBasePath={staticBasePath} />
        <CustomerReviews staticBasePath={staticBasePath} />
        <Pricing isAuthenticated={isAuthenticated} />
        <SupplementaryInformation staticBasePath={staticBasePath} />
        <TransformDataSecurity staticBasePath={staticBasePath} />
      </div>
    </>
  );
};

export default Landing;
