import React, { useState, useEffect } from "react";
import Pagination from "./user-profile/Pagination";
import LendingPricingSvg from "../svg/LendingPricingSvg";
import { userFoundData } from "../utils/apis";
import ReportBlock from "./reports/ReportBlock";
import { handleClickToCopy } from "../utils/copyToClipboard";
import { formatStampDetails } from "../utils/formatStampDetails";
import LogoVBSvg from "../svg/LogoVBSvg";
import LogoBlockchainSvg from "../svg/LogoBlockchainSvg";
import LogoPDFSvg from "../svg/LogoPDFSvg";
import { URLs } from "../utils/apis";
import PropTypes from "prop-types";
const UserFoundContent = ({ staticBasePath }) => {
  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    stamp_history: [],
    current_page: 1,
    total_pages: 1,
    persistent_ID: "",
    current_display_name: "",
    current_user_ID: "",
    current_account_status: "Not Verified",
    total_stamps: "",
    sets: "",
    download_csv: "",
  });

  useEffect(() => {
    const regex = /^(0x)?[0-9a-fA-F]{40}$/;
    const params = new URLSearchParams(window.location.search);
    const uidParam = params.get("uid");
    const checkValid = regex.test(uidParam) || uidParam === "";

    if (checkValid) {
      userFoundData(1, setInputValues, uidParam);
    }
  }, []);

  // Handle pagination
  const handlePageChange = (newPage) => {
    const regex = /^(0x)?[0-9a-fA-F]{40}$/;
    const params = new URLSearchParams(window.location.search);
    const uidParam = params.get("uid");
    const checkValid = regex.test(uidParam) || uidParam === "";

    if (checkValid) {
      userFoundData(newPage, setInputValues, uidParam); // Call the function with the new page number
    }
  };

  return (
    <div className="reports reports-producer user-found">
      <div className="reports-text rb-text-center">
        <img
          src={`${staticBasePath}public/images/svg/vb-icon-verified.svg`}
          alt="Green checkmark"
          className="rb-result-img align-center"
        />
        <h1>User Found!</h1>
      </div>
      <div className="reports-content">
        <div className="reports-head rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap">
          <div className="reports-head-left">
            {inputValues.persistent_ID && (
              <div className="reports-head-left-block">
                <span>Persistent ID:</span>
                <b>{inputValues.persistent_ID}</b>
              </div>
            )}

            {inputValues.current_display_name && (
              <div className="reports-head-left-block">
                <span>Current Display Name:</span>
                <b>{inputValues.current_display_name}</b>
              </div>
            )}
            {inputValues.current_user_ID && (
              <div className="reports-head-left-block">
                <span>Current User ID:</span>
                <b>{inputValues.current_user_ID}</b>
              </div>
            )}
            {inputValues.current_account_status && (
              <div className="reports-head-left-block">
                <span>Current Account Status:</span>
                <b>{inputValues.current_account_status}</b>
              </div>
            )}
          </div>
          <div className="reports-head-right">
            <div className="reports-blocks rb-text-center">
              {inputValues?.total_stamps && (
                <ReportBlock
                  Background="#EDF0E5"
                  Color="#000000"
                  Text="Total Stamps"
                  Number={inputValues?.total_stamps}
                  position="top-right"
                  text=""
                  link=""
                />
              )}
              {inputValues?.sets && (
                <ReportBlock
                  Background="#1B2F28"
                  Color="#FFFFFF"
                  Text="Sets"
                  Number={inputValues?.sets}
                  position="top-right"
                  text=""
                  link=""
                />
              )}
            </div>
          </div>
        </div>
        <div className="html-table-wrap">
          <div className="reports-table-head rb-display-flex rb-justify-content-space-between">
            <div className="reports-table-head-left">Stamp History</div>
          </div>
          {inputValues["stamp_history"].length > 0 ? (
            <div className="html-table">
              <table>
                <thead>
                  <tr>
                    <th>Content ID</th>
                    <th>Timestamp</th>
                    <th>Receipt Link</th>
                    <th>Set ID/Name</th>
                  </tr>
                </thead>
                <tbody>
                  {inputValues["stamp_history"].map(
                    (stamp_history_item, index) => (
                      <tr key={index}>
                        <td>
                          {stamp_history_item.content_ID
                            ? formatStampDetails(stamp_history_item.content_ID)
                            : "-"}
                          <img
                            className="html-table-copy"
                            src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                            alt="copy"
                            onClick={() =>
                              handleClickToCopy(stamp_history_item.content_ID)
                            }
                          />
                        </td>
                        <td>{stamp_history_item.timestamp}</td>
                        <td>
                          {stamp_history_item.receipt_link_vbase && (
                            <a
                              href={stamp_history_item.receipt_link_vbase}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <LogoVBSvg />
                            </a>
                          )}
                          {stamp_history_item.receipt_link_blockchain && (
                            <a
                              href={stamp_history_item.receipt_link_blockchain}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <LogoBlockchainSvg />
                            </a>
                          )}

                          {stamp_history_item.receipt_link_pdf && (
                            <a
                              href={stamp_history_item.receipt_link_pdf}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <LogoPDFSvg />
                            </a>
                          )}
                        </td>
                        <td>
                          {stamp_history_item.set_ID_name
                            ? formatStampDetails(stamp_history_item.set_ID_name)
                            : "-"}
                          <img
                            className="html-table-copy"
                            src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                            alt="copy"
                            onClick={() =>
                              handleClickToCopy(stamp_history_item.set_ID_name)
                            }
                          />
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No data available.</p>
          )}
        </div>
        {/* Pagination Controls */}
        {inputValues["total_pages"] > 1 && (
          <Pagination
            currentPage={inputValues["current_page"]}
            totalPages={inputValues["total_pages"]}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      <div className="reports-buttons rb-display-flex rb-align-items-center">
        <a
          href={URLs.VERIFY_URL}
          className="button rb-btn button-dark rb-display-flex rb-justify-content-center rb-align-items-center"
        >
          <LendingPricingSvg />
          Back
        </a>
      </div>
    </div>
  );
};

UserFoundContent.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
};
export default UserFoundContent;
