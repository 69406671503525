import Web3 from "web3";
const { sha3_256 } = require("js-sha3");

/**
 * Calculates a SHA3-256 hash of a string.
 * @param {string} data - The input string.
 * @returns {string} The SHA3-256 hash as a hex string prefixed with '0x'.
 */
export function sha3_256_hash_string(data) {
  const encoder = new TextEncoder();
  const byteArray = encoder.encode(data); // Convert string to bytes (Uint8Array)
  const hash = sha3_256.update(byteArray).hex(); // Compute the hash
  return "0x" + hash; // Add '0x' prefix
}

/**
 * Calculates a SHA3-256 hash of a byte array.
 * @param {Uint8Array} byteArray - The input byte array.
 * @returns {string} The SHA3-256 hash as a hex string prefixed with '0x'.
 */
export function sha3_256_hash_bytes(byteArray) {
  const hash = sha3_256.update(byteArray).hex(); // Compute the hash
  return "0x" + hash; // Add '0x' prefix
}

/**
 * Calculates a keccak256 hash of a byte array.
 * @param {Uint8Array} byteArray - The input byte array.
 * @returns {string} The SHA3-256 hash as a hex string prefixed with '0x'.
 */
export function keccak256_hash_bytes(byteArray) {
  const hexStringArray = byteArray.reduce((acc, byte) => {
    return acc + ("00" + byte.toString(16)).slice(-2);
  }, "");

  const web3 = new Web3();
  const hash = web3.utils.soliditySha3({
    type: "bytes",
    value: hexStringArray,
  });
  return hash;
}
