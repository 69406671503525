import React from "react";
import LendingPricingSvg from "../../svg/LendingPricingSvg";
import CurrentPlanSvg from "../../svg/CurrentPlanSvg";

const Pricing = ({isAuthenticated}) => {

  return (
    <section className="pricing">
      <div className="landing__container">

        <div className="pricing__title">Select Your Best Fit</div>
        <div className="pricing__text">
          Plans built for every team.
        </div>

        <div className="pricing-block rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap">
          <div className="pricing-block-item pricing-block-item-first rb-display-flex">
            <div className="pricing-block-item-head">
              <div className="pricing-block-item-head-title">
                DIY
              </div>
              <div className="pricing-block-item-head-subtitle">
              </div>
            </div>
            <div className="pricing-block-item-body">

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg/>
                <div className="pricing-block-item-body-item-text">
                  Use open source SDKs

                </div>
              </div>
              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg/>
                <div className="pricing-block-item-body-item-text">
                  Your wallets, your queries, your
                  <span>infrastructure</span>
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg/>
                <div className="pricing-block-item-body-item-text">
                  Unlimited free stamps and
                  <span>validations</span>
                </div>
              </div>

            </div>
            <div className="pricing-page-block-item-footer pricing-block-item-footer rb-display-flex rb-justify-content-center">

              <div className="pricing-block-buttons rb-display-flex rb-justify-content-center rb-align-items-center">
                <a className="button button-white rb-display-flex rb-align-items-center"
                   target="_blank"
                   href="https://docs.vbase.com/">
                  Get Started
                </a>
              </div>

            </div>

          </div>
          <div className="pricing-block-item pricing-block-item-second rb-display-flex">
            <div className="pricing-block-item-head">
              <div className="pricing-block-item-head-title">
                Free Forever
              </div>
              <div className="pricing-block-item-head-subtitle">
              </div>
            </div>
            <div className="pricing-block-item-body">


              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg/>
                <div className="pricing-block-item-body-item-text">
                  100 stamps/month
                  <span>(+$0.20/extra)</span>
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg/>
                <div className="pricing-block-item-body-item-text">
                  Unlimited Validations
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg/>
                <div className="pricing-block-item-body-item-text">
                  E-mail and chat support
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg/>
                <div className="pricing-block-item-body-item-text">
                  vBase APIs & Application Hub
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg/>
                <div className="pricing-block-item-body-item-text">
                  vBase DataPIT listing
                </div>
              </div>


            </div>
            <div
              className="pricing-page-block-item-footer pricing-block-item-footer rb-display-flex rb-justify-content-center">
              {isAuthenticated ? (
                <CurrentPlanSvg/>
              ) : (

                <div className="pricing-block-buttons rb-display-flex rb-justify-content-center rb-align-items-center">
                  <a className="button button-white rb-display-flex rb-align-items-center"
                     target="_blank"
                     href="/accounts/signup/">
                    Get Started
                  </a>
                </div>
              )}

            </div>

          </div>
          <div className="pricing-block-item pricing-block-item-third rb-display-flex">
            <div className="pricing-block-item-head">
              <div className="pricing-block-item-head-title">
                $50/month
              </div>
              <div className="pricing-block-item-head-subtitle">
                First month free
              </div>
            </div>
            <div className="pricing-block-item-body">


              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg/>
                <div className="pricing-block-item-body-item-text">
                  400 stamps/month
                  <span>(+$0.12/extra)</span>
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg/>
                <div className="pricing-block-item-body-item-text">
                  Managed service for S3,
                  <span>Snowflake, Dropbox and more</span>
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg/>
                <div className="pricing-block-item-body-item-text">
                  White glove onboarding
                </div>
              </div>

              <div className="pricing-block-item-body-item rb-display-flex">
                <LendingPricingSvg/>
                <div className="pricing-block-item-body-item-text">
                  Featured vBase DataPIT listing
                </div>
              </div>


            </div>
            <div className="pricing-block-item-footer">
              <div className="pricing-block-buttons rb-display-flex rb-justify-content-center rb-align-items-center">
                {isAuthenticated ? (
                  <a className="button button-dark rb-display-flex rb-align-items-center"
                     target="_blank"
                     href="https://www.vbase.com/contact/">
                    Get Started
                  </a>
                ) : (
                  <a className="button button-dark rb-display-flex rb-align-items-center"
                     target="_blank"
                     href="/accounts/signup/">
                    Get Started
                  </a>
                )}

              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  )
    ;
};

export default Pricing;
