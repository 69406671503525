import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { motion } from "framer-motion";
import { fetchCreateNameBucket } from "../utils/apis";

const ConfirmationLoadingBucketCreating = ({
  titleConfirmation = "",
  textConfirmation = "",
  titleLoading = "",
  textLoading = "",
  nameBucket = "",
  onNameBucketError,
  onNameBucketSuccess,
  onNameBucket = "",
  onShowConfirmationBucketCreate,
  onUpdateStampQuota,
}) => {
  // Confirmation create bucket an show loading
  const [loading, setLoading] = useState(false);

  // Memoize the callback function to avoid unnecessary re-renders
  const handleLoading = useCallback((loading) => {
    setLoading(loading);
  }, []);

  // Hide pop up
  const handleShowConfirmationBucketCreate = () => {
    onShowConfirmationBucketCreate(false);
  };

  // Fetch Create bucket
  const handleCreateBucket = async () => {
    const requestData = {
      // Name of Bucket
      name: nameBucket,
    };

    fetchCreateNameBucket(
      requestData,
      onNameBucketError,
      onNameBucketSuccess,
      onNameBucket,
      onShowConfirmationBucketCreate,
      onUpdateStampQuota,
      handleLoading,
    );
  };

  return (
    <div className="rb-loading-wrap rb-confirmation-bucket-wrap">
      {loading && (
        <div className="rb-loading__loader">
          <motion.span
            animate={{
              rotate: 360,
            }}
            transition={{
              repeat: Infinity,
              duration: 1,
              ease: "linear",
            }}
          />
        </div>
      )}
      <div className="rb-loading__title">
        {loading ? titleLoading : titleConfirmation}
      </div>
      <div className="rb-loading__title">
        {loading ? "" : "Collection Name: " + nameBucket}
      </div>
      <div className="rb-loading__text">
        {loading ? textLoading : textConfirmation}
      </div>

      {!loading && (
        <>
          <div className="rb-loading__title rb-confirmation__title">
            Create new Collection?
          </div>
          <div className="rb-confirmation__buttons rb-display-flex rb-justify-content-space-between">
            <button
              className="button rb-btn rb-btn-black rb-display-flex rb-align-items-center"
              onClick={() => handleCreateBucket()}
            >
              Yes
            </button>
            <button
              className="button rb-btn rb-btn-white rb-display-flex rb-align-items-center"
              onClick={() => handleShowConfirmationBucketCreate()}
            >
              No
            </button>
          </div>
        </>
      )}
    </div>
  );
};

ConfirmationLoadingBucketCreating.propTypes = {
  titleConfirmation: PropTypes.string.isRequired,
  textConfirmation: PropTypes.string.isRequired,
  titleLoading: PropTypes.string.isRequired,
  textLoading: PropTypes.string.isRequired,
  nameBucket: PropTypes.string.isRequired,
  onNameBucketError: PropTypes.func.isRequired,
  onNameBucketSuccess: PropTypes.func.isRequired,
  onNameBucket: PropTypes.func.isRequired,
  onShowConfirmationBucketCreate: PropTypes.func.isRequired,
  onUpdateStampQuota: PropTypes.func.isRequired,
};

export default ConfirmationLoadingBucketCreating;
