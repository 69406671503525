import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce";
import {
  fetchAllBucketsIsPinned,
  fetchAllBucketsIsNotPinned,
  URLs,
} from "../../utils/apis";
import { formatStampDetails } from "../../utils/formatStampDetails";
import { handleClickToCopy } from "../../utils/copyToClipboard";
import Pagination from "./Pagination";
import IsPinnedBucketWindow from "./IsPinnedBucketWindow";
import LendingPricingSvg from "../../svg/LendingPricingSvg";

const BucketNames = ({
  staticBasePath,
  onShowConfirmationBucketCreate,
  nameBucket,
  onNameBucket,
  onNameBucketError,
  nameBucketSuccess,
  nameBucketError,
}) => {
  //Bucket validation
  const validationBucket = (value) => {
    const unsafeCharacters = /[<>#%{}|\\^~[\]`]/g;
    const foundUnsafeChars = value.match(unsafeCharacters);

    if (value.length < 3 || value.length > 250) {
      onNameBucketError(
        "Value error: Name must be between 3 and 250 characters long.",
      );
      return false;
    } else if (foundUnsafeChars) {
      onNameBucketError(
        `Invalid name. Character not allowed: ${[
          ...new Set(foundUnsafeChars),
        ].join(", ")}`,
      );
      return false;
    } else {
      onNameBucketError(false);
      return true;
    }
  };

  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    userbuckets: [],
    current_page: 1,
    total_pages: 1,
  });

  const [inputValuesIsNotPinned, setInputValuesIsNotPinned] = useState({
    userbuckets: [],
    current_page: 1,
    total_pages: 1,
  });

  //Successfully is Pinned name
  const [nameBucketIsPinned, setNameBucketIsPinned] = useState("");

  // Show pop up confirmation
  const handleConfirmation = () => {
    if (validationBucket(nameBucket)) {
      onShowConfirmationBucketCreate(true);
    }
  };
  const handlePageChange = (newPage) => {
    fetchAllBucketsIsPinned(newPage, setInputValues); // Call the function with the new page number
  };

  const handlePageChangeIsNotPinned = (newPage) => {
    fetchAllBucketsIsNotPinned(newPage, setInputValuesIsNotPinned); // Call the function with the new page number
  };

  //Is Pinned Bucket Name
  const [showIsPinnedWindow, setShowIsPinnedWindow] = useState(false);

  // Bucket Name id
  const [bucketID, setBucketID] = useState("");

  // Bucket Is Pinned
  const [isPinned, setIsPinned] = useState(false);

  // Show window is Pinned
  const handleClickIsPinnedWindow = (id, pinned) => {
    setBucketID(id);
    setIsPinned(pinned);
    setShowIsPinnedWindow(true);
    document.body.style.overflow = "hidden";
  };

  useEffect(() => {
    fetchAllBucketsIsPinned(1, setInputValues);
    fetchAllBucketsIsNotPinned(1, setInputValuesIsNotPinned);
  }, [nameBucketSuccess, nameBucketIsPinned]); // updating the list after adding a new Bucket Name

  // Debounce delay for validation
  const validateNameBucketDebounce = useCallback(
    debounce((value) => {
      validationBucket(value);
    }, 2000), // Adjust debounce delay as needed
    [],
  );

  // Change input Bucket
  const handleChangeBucket = (e) => {
    const value = e.target.value;
    onNameBucket(value);
    validateNameBucketDebounce(value);
  };

  return (
    <div className="user-profile-tabs-content-item bucket-names">
      <h2>Create a Collection</h2>
      <form>
        <div className="user-profile-line rb-display-flex rb-justify-content-center">
          <label
            htmlFor="name"
            className="rb-display-flex rb-align-items-center"
          >
            New collection name:
          </label>
          <div className="bucket-names-input-wrap">
            <input
              type="text"
              className={`rb-input bucket-names-input ${
                nameBucketError ? "error" : ""
              } ${nameBucketSuccess ? "success" : ""}`}
              id="name"
              value={nameBucket}
              placeholder="Enter new collection name"
              onChange={handleChangeBucket}
            />
            {nameBucketError && (
              <div className="bucket-names-input-error">{nameBucketError}</div>
            )}
            {nameBucketSuccess && (
              <div className="bucket-names-input-success">
                {nameBucketSuccess}
              </div>
            )}
          </div>
          <div className="bucket-names-buttons rb-display-flex rb-align-items-center">
            <button
              className="button button-dark"
              type="button"
              onClick={() => handleConfirmation()}
            >
              Create
            </button>
          </div>
        </div>
      </form>
      <h3>Manage Collections</h3>
      <div className="bucket-names-table">
        {showIsPinnedWindow && (
          <div className="rb-screen">
            <IsPinnedBucketWindow
              setShowIsPinnedWindow={setShowIsPinnedWindow}
              bucketID={bucketID}
              isPinned={isPinned}
              setNameBucketIsPinned={setNameBucketIsPinned}
            />
          </div>
        )}

        <div className="html-table-wrap">
          <div className="bucket-names-table-head">
            <div className="bucket-names-table-head-left">
              Pinned Collections
            </div>
            <p>
              Pinned Collections are available to be selected on the Stamp page
            </p>
          </div>
          {inputValues["userbuckets"].length > 0 ? (
            <div className="html-table">
              <table>
                <thead>
                  <tr>
                    <th>Collection Name</th>
                    <th>Collection ID</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {inputValues["userbuckets"].map((userbuckets_item, index) => (
                    <tr key={index}>
                      <td>{userbuckets_item.name}</td>
                      <td>
                        {userbuckets_item.cid
                          ? formatStampDetails(userbuckets_item.cid)
                          : "-"}
                        <img
                          className="html-table-copy"
                          src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                          alt="copy"
                          onClick={() =>
                            handleClickToCopy(userbuckets_item.cid)
                          }
                        />
                      </td>
                      <td>
                        <button
                          onClick={() =>
                            handleClickIsPinnedWindow(
                              userbuckets_item.id,
                              false,
                            )
                          }
                        >
                          Unpin
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No Collections.</p>
          )}
        </div>
        {/* Pagination Controls */}
        {inputValues["total_pages"] > 1 && (
          <Pagination
            currentPage={inputValues["current_page"]}
            totalPages={inputValues["total_pages"]}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      <div className="bucket-names-table">
        <div className="html-table-wrap">
          <div className="bucket-names-table-head">
            <div className="bucket-names-table-head-left">
              All unpinned Collections
            </div>
            <p>
              Unpinned Collections are those that you’ve created but are not
              pinned on the Stamp page menu
            </p>
          </div>
          {inputValuesIsNotPinned["userbuckets"].length > 0 ? (
            <div className="html-table">
              <table>
                <thead>
                  <tr>
                    <th>Collection Name</th>
                    <th>Collection ID</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {inputValuesIsNotPinned["userbuckets"].map(
                    (userbuckets_item, index) => (
                      <tr key={index}>
                        <td>{userbuckets_item.name}</td>
                        <td>
                          {userbuckets_item.cid
                            ? formatStampDetails(userbuckets_item.cid)
                            : "-"}
                          <img
                            className="html-table-copy"
                            src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                            alt="copy"
                            onClick={() =>
                              handleClickToCopy(userbuckets_item.cid)
                            }
                          />
                        </td>
                        <td>
                          <button
                            onClick={() =>
                              handleClickIsPinnedWindow(
                                userbuckets_item.id,
                                true,
                              )
                            }
                          >
                            Pin
                          </button>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No Collections.</p>
          )}
        </div>
        {/* Pagination Controls */}
        {inputValuesIsNotPinned["total_pages"] > 1 && (
          <Pagination
            currentPage={inputValuesIsNotPinned["current_page"]}
            totalPages={inputValuesIsNotPinned["total_pages"]}
            onPageChange={handlePageChangeIsNotPinned}
          />
        )}
      </div>
      {showIsPinnedWindow && <div className="rb-loading-bg"></div>}

      <div className="reports-buttons bucket-names-back-buttons rb-display-flex rb-align-items-center">
        <a
          href={URLs.STAMP_URL}
          className="button rb-btn button-dark rb-display-flex rb-justify-content-center rb-align-items-center"
        >
          <LendingPricingSvg />
          Back to Stamper
        </a>
      </div>
    </div>
  );
};
BucketNames.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  onUpdateStampQuota: PropTypes.func,
  onShowConfirmationBucketCreate: PropTypes.func.isRequired,
  showConfirmationBucketCreate: PropTypes.bool,
  nameBucket: PropTypes.string.isRequired,
  nameBucketSuccess: PropTypes.string.isRequired,
  nameBucketError: PropTypes.string.isRequired,
  onNameBucketError: PropTypes.func.isRequired,
  onNameBucketSuccess: PropTypes.func.isRequired,
  onNameBucket: PropTypes.func.isRequired,
};
export default BucketNames;
