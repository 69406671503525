import React from "react";
import { createRoot } from "react-dom/client";
import StampForm from "./pages/StampForm";
import VerifyForm from "./pages/VerifyForm";
import PricingForm from "./pages/PricingForm";
import SubscriptionSuccessPage from "./pages/SubscriptionSuccessPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import UserProfile from "./pages/UserProfile";
import SubscriptionNotFoundPage from "./pages/SubscriptionNotFoundPage";
import Reports from "./pages/Reports";
import ReportsProducer from "./pages/ReportsProducer";
import UserFound from "./pages/UserFound";

const props = window.props || {};
const staticBasePath = "/static/";

const stampRootMount = document.getElementById("stamp-root");
const verifyRootMount = document.getElementById("verify-root");
const headerRootMount = document.getElementById("header-root");
const footerRootMount = document.getElementById("footer-root");
const profileRootMount = document.getElementById("profile-root");
const userProfileRootMount = document.getElementById("user-profile-root");
const billingRootMount = document.getElementById("billing-root");
const billingNotFoundRootMount = document.getElementById(
  "billing-not-found-root",
);
const reportsRootMount = document.getElementById("reports-root");
const reportsProducerRootMount = document.getElementById(
  "reports-producer-root",
);
const userFoundRootMount = document.getElementById("user-found-root");

function getValue(key) {
  const element = document.getElementById(key);
  if (element) {
    // Clean up the value by trimming and removing surrounding quotes
    let cleanedText = element.textContent.trim();
    if (cleanedText.startsWith('"') && cleanedText.endsWith('"')) {
      cleanedText = cleanedText.slice(1, -1);
    }
    return cleanedText;
  }
  return "";
}
function getValueAsBoolean(key) {
  const element = document.getElementById(key);
  if (element) {
    const trimmedText = element.textContent.trim().toLowerCase();
    return trimmedText === "true";
  }
  return false;
}

const userIsAuthenticated = getValueAsBoolean("user-is-authenticated") || false;
const userEmail = getValue("user-email");
const userShortName = getValue("user-short-name");
const userNameValue = getValue("user-name");

const djangoContext = {
  userIsAuthenticated,
  userEmail,
  userShortName,
  userNameValue,
};

if (stampRootMount) {
  const root = createRoot(stampRootMount);
  root.render(
    <StampForm
      {...props}
      staticBasePath={staticBasePath}
      djangoContext={djangoContext}
    />,
  );
}

if (headerRootMount) {
  const root = createRoot(headerRootMount);
  root.render(
    <Header
      {...props}
      staticBasePath={staticBasePath}
      djangoContext={djangoContext}
    />,
  );
}
if (footerRootMount) {
  const root = createRoot(footerRootMount);
  root.render(<Footer {...props} staticBasePath={staticBasePath} />);
}

if (verifyRootMount) {
  const root = createRoot(verifyRootMount);
  root.render(
    <VerifyForm
      {...props}
      staticBasePath={staticBasePath}
      djangoContext={djangoContext}
    />,
  );
}

if (profileRootMount) {
  const root = createRoot(profileRootMount);
  root.render(
    <PricingForm
      {...props}
      staticBasePath={staticBasePath}
      djangoContext={djangoContext}
    />,
  );
}

if (billingRootMount) {
  const root = createRoot(billingRootMount);
  root.render(
    <SubscriptionSuccessPage
      {...props}
      staticBasePath={staticBasePath}
      djangoContext={djangoContext}
    />,
  );
}

if (billingNotFoundRootMount) {
  const root = createRoot(billingNotFoundRootMount);
  root.render(
    <SubscriptionNotFoundPage
      {...props}
      staticBasePath={staticBasePath}
      djangoContext={djangoContext}
    />,
  );
}

if (userProfileRootMount) {
  const root = createRoot(userProfileRootMount);
  root.render(
    <UserProfile
      {...props}
      staticBasePath={staticBasePath}
      djangoContext={djangoContext}
    />,
  );
}

if (reportsRootMount) {
  const root = createRoot(reportsRootMount);
  root.render(
    <Reports
      {...props}
      staticBasePath={staticBasePath}
      djangoContext={djangoContext}
    />,
  );
}

if (reportsProducerRootMount) {
  const root = createRoot(reportsProducerRootMount);
  root.render(
    <ReportsProducer
      {...props}
      staticBasePath={staticBasePath}
      djangoContext={djangoContext}
    />,
  );
}
if (userFoundRootMount) {
  const root = createRoot(userFoundRootMount);
  root.render(
    <UserFound
      {...props}
      staticBasePath={staticBasePath}
      djangoContext={djangoContext}
    />,
  );
}
