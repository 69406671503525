import PropTypes from "prop-types";
import React from "react";
import SearchableSelect from "../utils/searchableSelect";
import { URLs } from "../utils/apis";
import Help from "./Help";
import "../../../static/assets/scss/components/stamp-bucket.scss";

const BucketSelect = ({
  activateSelectBucket,
  onActivateSelectBucket,
  selectBuckets,
  onSelectedOptionBucket,
  helpIsActive,
}) => {
  const handleCheckboxChange = (event) => {
    onActivateSelectBucket(event.target.checked);
  };
  // selecting Bucket Name for Stamp page
  return (
    <div className="stamp-bucket rb-display-flex rb-justify-content-center rb-flex-wrap-wrap">
      <div className="stamp-bucket-activate-wrap">
        <input
          id="stamp-belongs"
          className="stamp-bucket-activate"
          type="checkbox"
          checked={activateSelectBucket}
          onChange={handleCheckboxChange}
        />
        <label
          htmlFor="stamp-belongs"
          className="stamp-bucket-activate-label rb-display-flex rb-align-items-center"
        >
          Stamp Belongs to Collection
        </label>
      </div>
      <div className="stamp-bucket-wrap rb-display-flex">
        {/*Select component*/}
        <SearchableSelect
          selectBuckets={selectBuckets}
          disabled={!activateSelectBucket}
          onSelectedOptionBucket={onSelectedOptionBucket}
        />

        <a
          className="rb-display-flex rb-justify-content-center rb-align-items-center"
          href={URLs.USER_BUCKET_URL}
        >
          +
        </a>
        {helpIsActive && (
          <Help
            position="right"
            text={{
              __html: `<p>You can assign your vBase Stamps to Collections (think of Collections like topics, for example: strategy1, strategy2) etc.</p>

<p>Someone verifying your stamps will be able to see how many Collections you've created and how many stamps belong to each Collection.</p>`,
            }}
            link=""
          />
        )}
      </div>
    </div>
  );
};

BucketSelect.propTypes = {
  activateSelectBucket: PropTypes.bool.isRequired,
  handleCheckboxChange: PropTypes.func,
  selectBuckets: PropTypes.array.isRequired,
  onSelectedOptionBucket: PropTypes.func.isRequired,
  helpIsActive: PropTypes.bool.isRequired,
  onActivateSelectBucket: PropTypes.func.isRequired,
};
export default BucketSelect;
