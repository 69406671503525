import React from "react";

const GuaranteedData = ({staticBasePath}) => {
  return (
    <section className="guaranteed">
      <div className="landing__container">
        <div className="guaranteed__title">Data Validation as a Service</div>
        <div className="guaranteed__text">
          <p>vBase offers a solution for businesses that need consistent and trustworthy data and documents. Assure your data’s timeliness and integrity are verifiable, and prove the authorship of any document with vBase’s independently verifiable, tamperproof data stamps.</p>
        </div>

        <div className="guaranteed__blocks">
          <div
            className="guaranteed__blocks-item rb-display-flex rb-align-items-center rb-justify-content-space-between">
            <div className="guaranteed__blocks-item-texts">
              <div className="guaranteed__blocks-item-texts-title">
                Trusted timestamps and data integrity
              </div>
              <div className="guaranteed__blocks-item-texts-text">
                <p>The vBase app verifiably secures data, creating a timestamped record of your data’s existence.</p>
              </div>
            </div>
            <div className="guaranteed__blocks-item-content">

              <div className="border-top"></div>
              <div className="border-bottom"></div>
              <div className="border-left"></div>
              <div className="border-right"></div>

              <div className="scalloped-top"></div>
              <div className="scalloped-bottom"></div>
              <div className="scalloped-left"></div>
              <div className="scalloped-right"></div>

              <div className="guaranteed__fingerprint">
                <div
                  className="guaranteed__fingerprint-top rb-display-flex rb-justify-content-center rb-align-items-flex-start">
                  <div className="guaranteed__fingerprint-top-browse">
                    <span>Browse for File</span>
                    <img
                      src={`${staticBasePath}public/images/svg/landing_browse_for_file.svg`}
                      alt="Browse For File"
                    />
                  </div>
                  <div className="guaranteed__fingerprint-top-arrow-wrap">
                    <img
                      src={`${staticBasePath}public/images/svg/landing_arrow.svg`}
                      alt="Arrow"
                      className="guaranteed__fingerprint-top-arrow"
                    />
                  </div>
                    <img
                      className="guaranteed__fingerprint-top-fingerprint"
                      src={`${staticBasePath}public/images/svg/landing_fingerprint.svg`}
                      alt="Fingerprint"
                    />


                  </div>
                  <div
                    className="guaranteed__fingerprint-bottom rb-display-flex rb-justify-content-space-between rb-align-items-flex-start">
                    <div className="guaranteed__fingerprint-bottom-item guaranteed__fingerprint-bottom-left">
                      <span>1. Calculate digital fingerprint</span>
                      <p>Load file into our browser app to
                        privately calculate its unique digital
                        fingerprint (aka contentID).</p>
                    </div>
                    <div className="guaranteed__fingerprint-bottom-item guaranteed__fingerprint-bottom-right">
                      <span>2. Publish fingerprint to blockchain</span>
                      <p>Verifiable fingerprint timestamp proves the file’s existence and the stamp’s author.</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div
            className="guaranteed__blocks-item rb-display-flex rb-align-items-center rb-justify-content-space-between guaranteed__blocks-text-right">
            <div className="guaranteed__blocks-item-content">

              <div className="border-top"></div>
              <div className="border-bottom"></div>
              <div className="border-left"></div>
              <div className="border-right"></div>

              <div className="scalloped-top"></div>
              <div className="scalloped-bottom"></div>
              <div className="scalloped-left"></div>
              <div className="scalloped-right"></div>

              <div className="guaranteed__stamp">
                <img
                  src={`${staticBasePath}public/images/svg/landing_stamp_found.svg`}
                  alt="Green checkmark"
                  className="rb-result-img align-center"
                />
                <h2 className="rb-header rb-header-stamp rb-text-center">Stamp Found!</h2>
                <div className="rb-account-info-wrap rb-display-flex rb-justify-content-center rb-align-items-center">
                  <div className="rb-account-info rb-account-info-found__user">
                    <div className="rb-account-info-key">Stamped By</div>
                    MyCompany LLC
                  </div>
                    <div className="rb-account-info rb-account-info-status">
                      <div className="rb-account-info-key">Account Status</div>
                      <img
                        src={`${staticBasePath}public/images/svg/verified.svg`}
                        alt="verified"
                        className="rb-verify-img"
                      />
                      Verified
                    </div>

                </div>
                <div className="rb-account-info rb-account-info-found__time">

                  <span className="rb-account-info-key rb-account-info-key__time">Timestamp: </span>
                  Dec 31, 2023 at 11:00:02 AM UTC &nbsp;&nbsp; <span className="rb-account-info-timedelta">3 days ago</span>
                </div>

              </div>
            </div>
            <div className="guaranteed__blocks-item-texts">
              <div className="guaranteed__blocks-item-texts-title">
                Verifiable anywhere, any time
              </div>
              <div className="guaranteed__blocks-item-texts-text">
                <p>Build your credibility. Anyone with whom you share
                  your file can <b>validate its timestamp and integrity</b> by
                  checking the blockchain fingerprint.</p>
              </div>
            </div>
          </div>
          <div
            className="guaranteed__blocks-item guaranteed__blocks-item-secure rb-display-flex rb-align-items-center rb-justify-content-space-between">
            <div className="guaranteed__blocks-item-texts">
              <div className="guaranteed__blocks-item-texts-title">
                Simple and Secure
              </div>
              <div className="guaranteed__blocks-item-texts-text">
                <p>Your file never leaves your computer, while its timestamp remains independently verifiable forever. </p>
              </div>
            </div>
            <div className="guaranteed__blocks-item-content rb-display-flex rb-justify-content-center rb-align-items-center">

              <div className="border-top"></div>
              <div className="border-bottom"></div>
              <div className="border-left"></div>
              <div className="border-right"></div>

              <div className="scalloped-top"></div>
              <div className="scalloped-bottom"></div>
              <div className="scalloped-left"></div>
              <div className="scalloped-right"></div>

              <div className="guaranteed__secure">
                <img
                  src={`${staticBasePath}public/images/svg/landing_secure.svg`}
                  alt="Simple and Secure"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
    ;
};

export default GuaranteedData;
